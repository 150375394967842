// import logo from './logo.svg';
import './App.css';
import product_json from './json/current_wmtm.json';
import categories_map_json from './json/categories_array.json';
import subcategories_map_json from './json/subcategories_array.json';

function App() {
  const categories_conv_array = JSON.parse(JSON.stringify(categories_map_json));
  const subcategories_conv_array = JSON.parse(JSON.stringify(subcategories_map_json));

  const categories_map = new Map(categories_conv_array.map(item => [item.raw_subcategory, item.category]));
  const subcategories_map = new Map(subcategories_conv_array.map(item => [item.raw_subcategory, item.subcategory]));

  var latest_timestamp = "";

  var products = JSON.parse(JSON.stringify(product_json))
  products.forEach((product) => {
    const subcategory = product.link.split('/')[2];

    if (subcategories_map.has(subcategory)) {
      product.category = categories_map.get(subcategory);
      product.subcategory = subcategories_map.get(subcategory);
    }

    else {
      product.category = "Other";
      product.subcategory = "Other";
    }

    product.colors.forEach((color) => {
      if (latest_timestamp < color.date) {
        latest_timestamp = color.date;
      } 
    });
  });

  var products_by_subcategory = new Map();
  products.forEach((product) => {
    var new_color_added = false;

    product.colors.forEach((color) => {
      if (color.date === latest_timestamp) {
        new_color_added = true;
      }
    });

    if (!new_color_added) {
      return;
    }

    if (products_by_subcategory.has(product.category)) {
      products_by_subcategory.get(product.category).push(product);
    }
    else {
      products_by_subcategory.set(product.category, [product]);
    }
  });

  return (
    <div className="App">
      <div className="App-body">
        <nav class="navbar navbar-dark bg-dark">
          <span class="navbar-brand">luludeltas</span>
        </nav>
        <div class="container">
          <p></p>

          {
            Array.from(products_by_subcategory.keys()).toSorted().map((subcategory) => (
              <div key={subcategory}>
                <h5>{subcategory} ({products_by_subcategory.get(subcategory).length} New Items)</h5>
                <ul>
                  {products_by_subcategory.get(subcategory).toSorted((a, b) => a.name > b.name).map((product) => 
                    (<li key={product.name}>
                      <a href={"https://shop.lululemon.com" + product.link}>{product.name}</a>
                    </li>))}
                    </ul>
              </div>
            ))
          }
        </div>
      </div>
      <div class="container">
        <p>Disclaimer: luludeltas is not in any way associated with lululemon athletica. All trademarks are property of their respective owners.</p>
      </div>
    </div>
  );
}

export default App;
